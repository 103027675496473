<template>
  <b-form-select
    id="type"
    v-model="typeProxy"
    :options="typeOptions"
  />
</template>
<script>
export default {
  name: 'DonationTypeSelect',
  props: {
    type: { type: String, default: '' },
  },
  data() {
    return {
      typeOptions: [
        { text: 'Select Type', value: '', disabled: true },
        { text: 'All', value: null },
        { text: 'Donation', value: 'donation' },
        { text: 'Live', value: 'live' },
        { text: 'Event', value: 'event' },
        { text: 'Fundraising', value: 'fundraising' },
      ],
    }
  },
  computed: {
    typeProxy: {
      get() {
        return this.type
      },
      set(value) {
        this.$emit('update:type', value)
      },
    },
  },
}
</script>
<style lang="">

</style>
